<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      @row-clicked="handleSelectStaff($event)"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(id)="{ item }">
        <div>
          <b-form-checkbox
            :id="'check-staff-' + item.id"
            v-model="item.isChecked"
            :name="'check-staff-' + item.id"
            @change="handleSelectStaff(item, $event)"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </div>
      </template>
      <template v-slot:cell(first_name)="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="$emit('pagination', $event)"
      @handleChangeTake="$emit('pagination')"
    />

    <b-row class="mt-3">
      <b-col>
        <b-button class="btn-cancel btn-width" variant="secondary" @click="$emit('hide')"
          >CANCEL</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="main-color btn-save btn-width" @click="selectStaff()">
          Select
          <!-- ({{ tempID.length }}) -->
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: [
    "filter",
    "items",
    "isBusy",
    "fields",
    "rows",
    "tempSelect",
    "selectStaffList",
  ],
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      tempData: [...this.selectStaffList],
    };
  },
  watch: {},
  mounted() {},
  methods: {
    handleSelectStaff(item, val) {
      item.isChecked = val != undefined ? val : !item.isChecked;
      if (item.isChecked) {
        this.tempData.push(item);
        this.tempSelect.push(item.id);
      } else {
        if (this.tempData.findIndex((el) => el.id == item.id) != -1) {
          const indexObj = this.tempData.findIndex((el) => el.id == item.id);
          const index = this.tempData.indexOf(item.id);
          this.tempData.splice(indexObj, 1);
          this.tempSelect.splice(index, 1);
        }
      }
    },
    selectStaff() {
      this.$emit("hide");
      this.$emit("setStaff", this.tempData);
    },
  },
};
</script>
