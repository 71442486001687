<template>
  <b-modal v-model="modalStaff" hide-footer size="xl">
    <template #modal-header>
      <div class="title-panel">Select Staff</div>
    </template>

    <b-container class="no-gutters bg-white">
      <b-input-group>
        <b-form-input
          type="search"
          class="search-bar"
          placeholder="Search ID, Staff name"
          v-model="filter.search"
          @keypress.enter="getStaffList"
        >
        </b-form-input>
        <b-input-group-append is-text>
          <b-iconstack font-scale="2" type="submit" @click="getStaffList">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
      </b-input-group>

      <TableStaff
        :checkBox="true"
        class="mt-2"
        :filter="filter"
        :items="items"
        :fields="fields"
        :rows="rows"
        @pagination="pagination"
        :isBusy="isBusy"
        @setStaff="setStaff"
        @hide="hide"
        :tempSelect="tempSelect"
        :selectStaffList="selectStaffList"
      />
    </b-container>
  </b-modal>
</template>
<script>
import TableStaff from "./TableStaff";
export default {
  props: ["selectStaffList", "sales_id", "selectStaffID"],
  components: { TableStaff },
  data() {
    return {
      modalStaff: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      items: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "sales_no",
          label: "Staff ID",
        },
        {
          key: "first_name",
          label: "Staff name",
          tdClass: "text-left",
        },
      ],
      tempSelect: [],
      rows: 0,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    getStaffList() {
      var ID = [...this.tempSelect, ...this.selectStaffID];
      this.isBusy = true;
      this.$Axios
        .post(`${process.env.VUE_APP_API}/salesperson/list`, this.filter)
        .then((res) => {
          if (res.data.result) {
            res.data.detail.data.map(
              (el) => (el.isChecked = ID.includes(el.id))
            );
            this.isBusy = false;
            this.items = res.data.detail.data;
            this.rows = res.data.detail.total;
          }
        });
    },
    pagination(page) {
      this.filter.page = page;
      this.getStaffList();
    },
    handleSearch() {
      this.filter.page = 1;
      this.getStaffList();
    },
    hide() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
      };
      this.modalStaff = false;
    },
    show() {
      this.items.map(
        (el) => (el.isChecked = this.selectStaffID.includes(el.id))
      );
      this.getStaffList();
      this.modalStaff = true;
    },
    setStaff(val) {
      this.$emit("setStaff", val);
    },
  },
};
</script>
<style scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .modal-header {
  display: block !important;
  padding: unset;
}
</style>
